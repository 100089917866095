import React, { useEffect, useState } from 'react'
import './contact.css'
import MetaTag from '../Meta/MetaTag'
import axios from 'axios'
import toast from 'react-hot-toast';
// import bg from './bg.jpg'

function Contact() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])

    const [formdata, setformdata] = useState({
        Name: '',
        Email: '',
        PhoneNumber: '',
        Message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setformdata(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://formspree.io/f/myyroelk', formdata);
            toast.success('Message Sent Successfully!');
            console.log(response);
        } catch (error) {
            console.log(error);
            toast.error("Error Occurred");
        }
    };
    return (
        <section className='contact-section'>
            <MetaTag title={'Contact Irodov Classes - Get in Touch for NEET Coaching'} description={' Contact Irodov Classes for inquiries about our NEET coaching programs. Reach out to us for admissions, queries, or any assistance you need.'} keyword={'Contact Irodov Classes, NEET coaching contact, Irodov Classes admissions, best NEET coaching contact'} />
            <div className='container'>
                <div className='bg'>

                </div>

                <div className='main-content'>
                    <div className='heading'>
                        <span>Contact Us</span>
                    </div>
                    <div className='form-main-container'>
                        <div className='adress-container'>
                            <h2>Contact Information</h2>
                            <div className='adress-detail'>
                                <div className='adress-box same-address'>
                                    <div className='logo'>
                                        <i class="ri-home-4-fill"></i>
                                    </div>
                                    <div className='adress'>
                                        <p>Sector-55 Gurgaon</p>
                                        <p>Palam Vihar Gurgaon</p>
                                    </div>
                                </div>
                                <div className='phone-box same-address'>
                                    <div className='logo'>
                                        <i class="ri-phone-fill"></i>
                                    </div>
                                    <div className='number'>
                                        <a href='tel:+919818280575'>9818280575</a>
                                        <a href='tel:+919971060575'>9971060575</a>
                                    </div>
                                </div>
                                <div className='mail-box same-address'>
                                    <div className='logo'>
                                        <i class="ri-mail-fill"></i>
                                    </div>
                                    <div className='mail'>
                                        <a href='mailto:navintiwari13476@gmail.com'>navintiwari13476@gmail.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className='name same-form-field'>
                                <input type='text' name='Name' placeholder='Name' value={formdata.Name} onChange={handleChange} />
                            </div>
                            <div className='email same-form-field'>
                                <input type='email' name='Email' placeholder='Email' value={formdata.Email} onChange={handleChange} />
                            </div>
                            <div className='phone same-form-field'>
                                <input type='text' name='PhoneNumber' placeholder='Phone NO.' value={formdata.PhoneNumber} onChange={handleChange} />
                            </div>
                            <div className='textarea'>
                                <textarea name='Message' placeholder='Your Message...' value={formdata.Message} onChange={handleChange}></textarea>
                            </div>
                            <button className='button-86' type='submit'>Submit</button>
                        </form>
                    </div>
                    <div className='map-container'>
                        <a href='https://www.google.com/maps/place/Sector+55,+Gurugram,+Haryana/@28.4253849,77.1018822,15z/data=!3m1!4b1!4m6!3m5!1s0x390d21fc499e8637:0xe1ed23b89bc2f5f8!8m2!3d28.4281173!4d77.1100123!16s%2Fg%2F1tddw4gg?entry=ttu'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14035.187560414448!2d77.10188222771839!3d28.42538493330192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d21fc499e8637%3A0xe1ed23b89bc2f5f8!2sSector%2055%2C%20Gurugram%2C%20Haryana!5e0!3m2!1sen!2sin!4v1714035787444!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact
