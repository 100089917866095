import React from 'react'
import './ClassesModePage.css'
import online from './online.jpg'
import offline from './offline.jpg'
import { Link } from 'react-router-dom'

function ClassesModePage() {
    return (
        <section className='classesMode-section'>
            <div className='container'>
                <div className='heading'>
                    <span>Classes Mode</span>
                    
                </div>
                <div className='main-container'>
                    <Link to={'online-courses'} className='same-col'>
                        <div className='img'>
                            <img src={online} alt='' />
                            <div className='img-content'>
                                <h2>Online Classes</h2>
                            </div>
                        </div>
                        <div className='content'>
                            <h2>Online Classes</h2>
                        </div>
                    </Link>
                    <Link to={'offline-courses'} className='same-col'>
                        <div className='img'>
                            <img src={offline} alt='' />
                            <div className='img-content'>
                                <h2>Offline Classes</h2>
                            </div>
                        </div>
                        <div className='content'>
                            <h2>Offline Classes</h2>
                        </div>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default ClassesModePage
