import React from 'react'
import Hero from '../Hero/Hero'
import NeetPhysicsByNaveen from '../NeetPhysicsByNaveen/NeetPhysicsByNaveen'
import StudentSays from '../StudentSays/StudentSays'
import AboutTiwariClassesHome from '../AboutTiwariClassesHome/AboutTiwariClassesHome'
import StudentSuccess from '../StudentSuccess/StudentSuccess'
import Query from '../Quarycomponent/Query'
import ClassesModePage from '../ClassesModePage/ClassesModePage'
import FeatureHomePage from '../FeatureHomePage/FeatureHomePage'
import MetaTag from '../Meta/MetaTag'

function Home() {
  return (
    <>
    <MetaTag title={'Welcome to Irodov Classes - Premier NEET Coaching Agency'} description={'Join Irodov Classes for top-notch NEET coaching both offline and online. Achieve your medical dreams with our expert guidance.'} keyword={'NEET coaching, Irodov Classes, offline NEET classes, online NEET classes, best NEET coaching agency'} />
      <Hero />
      {/* <NeetPhysicsByNaveen /> */}
      {/* <StudentSays /> */}
      <AboutTiwariClassesHome />
      <ClassesModePage />
      <StudentSuccess />
      <FeatureHomePage />
      <Query />
    </>
  )
}

export default Home
