import React from 'react'
import './FeatureHomePage.css'
import studymaterial from './studymaterial.jpg'
import practicetest from './practicetest.jpg'
import doubt from './doubt.jpg'
import experiment from './experiment.jpg'
import { Link } from 'react-router-dom'

function FeatureHomePage() {
  return (
    <section className='featurehome-section'>
      <div className='container'>
        <div className='header'>
            <span>Extra Benefits Of Joining Us</span>
        </div>
        <div className='main-container'>
            <Link to={'/study-material'} className='col'>
                <div className='img'>
                    <img src={studymaterial} alt='' />
                </div>
                <div className='content'>
                    <span>Study Material</span>
                </div>
            </Link>
            <Link to={'/test'} className='col'>
                <div className='img'>
                    <img src={practicetest} alt='' />
                </div>
                <div className='content'>
                    <span>Practice Tests</span>
                </div>
            </Link>
            <Link to={'/doubt-resolution'} className='col'>
                <div className='img'>
                    <img src={doubt} alt='' />
                </div>
                <div className='content'>
                    <span>Doubt Resolution</span>
                </div>
            </Link>
            <Link to={'/physics-experiments-activities'} className='col'>
                <div className='img'>
                    <img src={experiment} alt='' />
                </div>
                <div className='content'>
                    <span>Physics Experiment & Activities</span>
                </div>
            </Link>
        </div>
      </div>
    </section>
  )
}

export default FeatureHomePage
